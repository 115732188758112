import React, { Component, useState } from 'react';
import Header from '../layout/Header';

const FORM_ENDPOINT = "https://api.ivrsurvey.net/api/mail/webContactForm";

const Contact_01 = () => {
    const [status, setStatus] = useState();
    const handleSubmit = (e) => {
        e.preventDefault();

        const inputs = e.target.elements;
        const data = {};

        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].name) {
                data[inputs[i].name] = inputs[i].value;
            }

            if (inputs[i].type === "submit") {
                inputs[i].innerText = "Please Wait...";
            }
        }

        fetch(FORM_ENDPOINT, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => {
            console.log(response);
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(() => 
            {setStatus("We'll be in touch soon.");
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].name) {
                    inputs[i].value = "";
                }

                if (inputs[i].type === "submit") {
                    inputs[i].innerText = "Submit Quote";
                }
            }
        })
        .catch((err) => setStatus(err.toString()));        
    };

    if (status) {
        alert("Thank you for your request! We'll be in touch soon.");
        /* return (
          <>
            <div className="text-2xl">Thank you!</div>
            <div className="text-md">{status}</div>
          </>
        ); */
      }
    
    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row ttm-contact-title-row">
                <div className="container-xl">
                <div className="row">
                    <div className="col-md-12"> 
                    <div className="title-box text-center">
                        <div className="page-title-heading">
                        <h1 className="title">Contact Us</h1>
                        </div>{/* /.page-title-captions */}
                        <div className="breadcrumb-wrapper">
                        <span>
                            <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                        </span>
                        <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                        <span>Contact</span>
                        </div>  
                    </div>
                    </div>{/* /.col-md-12 */}  
                </div>{/* /.row */}  
                </div>{/* /.container */}                      
            </div>
            {/* page-title end*/}
            <section className="ttm-row zero-padding-section clearfix">
                <div className="container-xl">
                <div className="row no-gutters">{/* row */}
                    <div className="col-lg-5">
                    <div className="spacing-9">
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                        <div className="title-header">
                            <h5>Come Visit Us At</h5>
                            <h2 className="title">Our Address</h2>
                        </div>
                        </div>{/* section title end */}
                        <div className="row">
                        <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                            <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                <i className="ti-location-pin" />
                                </div>
                            </div>
                            <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                <h5>Turkey Address</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                <p>Altıntepe Mh. İstasyon Yolu Sk. No:3 34840 Maltepe/İstanbul</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* separator */}
                        <div className="separator">
                        <div className="sep-line mt-4 mb-4" />
                        </div>
                        {/* separator */}
                        <div className="row">
                        <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                            <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                <i className="ti-location-pin" />
                                </div>
                            </div>
                            <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                <h5>Indonesia Address</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                <p>Jl. Mertanadi No.29, Kerobokan Kelod, Kec. Kuta Utara, Kabupaten Badung, Bali 80361</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* separator */}
                        <div className="separator">
                        <div className="sep-line mt-4 mb-4" />
                        </div>
                        {/* separator */}
                        <div className="row">
                        <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                            <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                <i className="ti ti-email" />
                                </div>
                            </div>
                            <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                <h5>Our Email</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                <p><a href="mailto:info@ivrsurvey.net">info@ivrsurvey.net</a></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-7">
                    <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                        </div>
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                        <div className="title-header">
                            <h5>Send Message</h5>
                            <h2 className="title">Drop Us A Line</h2>
                        </div>
                        </div>{/* section title end */}
                        <form id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post" action={FORM_ENDPOINT} onSubmit={handleSubmit}>
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                            <input name="name" type="text" className="form-control bg-white" placeholder="Full Name*" required="required" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                            <input name="phone" type="text" placeholder="Phone Number*" required="required" className="form-control bg-white" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                            <input name="address" type="text" placeholder="Email Address*" required="required" className="form-control bg-white" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                            <input name="subject" type="text" placeholder="Subject" required="required" className="form-control bg-white" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                            <textarea name="message" rows={5} placeholder="Write A Massage..." required="required" className="form-control bg-white" defaultValue={""} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-left">
                            <button type="submit" id="submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" value>
                                Submit Quote
                            </button>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                {/* row end */}
                </div>
            </section>
            <section className="ttm-row map-section res-991-p-0 clearfix">
                <div className="map-wrapper">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3013.330302733161!2d29.096231915660702!3d40.95234243081353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac6fb55ebffff%3A0xc895c4db017b0106!2zUG9ww7xsZXIgWWF6xLFsxLFt!5e0!3m2!1str!2str!4v1645994947005!5m2!1str!2str" height="100%"></iframe>
                </div>
            </section>
        </div>
        )
};

export default Contact_01;