import React, { Component } from 'react';
import { MDBProgress } from 'mdbreact';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import Header from '../layout/Header';

export class Aboutus extends Component {
    render() {
        var slick_slider = {
          dots: false,
          arrow: true,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row ttm-about-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">About Us</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'} ><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>About Us</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                {/* aboutus-section */}
                <section className="ttm-row aboutus-section clearfix">
                    <div className="container-xl">
                    <div className="row">{/* row */}
                        <div className="col-lg-6">
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                            <div className="title-header">
                            <h5>About us</h5>
                            <h2 className="title">We are here for IVR Solution with years of <span>experiences</span></h2>
                            </div>
                            <div className="title-desc">
                            <p>We have been developing a lot of software in different research companies since 2003. We met in 2019 for another new challenge, and here we are for IVR Survey</p>
                            <p>Half of the team became experts in research report systems and the other half in telephone-assisted survey systems. Professional researchers have been added to this team and IVR survey is ready for the services.</p>
                            <p>We continue our way with the awareness of the system, which has been completed in about 1.5 years now. This is just the beginning of our journey.</p>
                            </div>
                        </div>{/* section title end */}
                        {/* row */}
                        <div className="row no-gutters mt-4 mb-4">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                                <h5>Research Solutions</h5>
                                <ul className="ttm-list ttm-list-style-icon">
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Expectation and satisfaction research </span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Perception research</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Social support evaluation research</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Agenda (National / Local) research</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Election Campaign, Pre-Poll Studies and Post-Poll Studies</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Brand  &amp; service positioning research</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Brand /Service preference determinations</span></li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                                <h5>Sector Experience</h5>
                                <ul className="ttm-list ttm-list-style-icon">
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Banks</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Insurance</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Council</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Public establishment</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Health Care</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Education</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">E-commercial</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Food</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Automotive</span></li>
                                </ul>
                            </div>
                        </div>{/* row END*/}
                        </div>
                        <div className="col-lg-6">
                        <div className="position-relative res-991-mt-30">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper text-sm-center">
                            <img className="img-fluid" src="../images/about/about-us-1.png" title="single-img-seven" alt="single-img-seven" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        </div>
                    </div>
                    {/* row end */}
                    </div>
                </section>
                {/* aboutus-section end*/}
                {/* progress-bar-section */}
                <section className="ttm-row zero-padding-section res-991-p-0 ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                        <div className="ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                            <div className="ttm-bg-layer-inner" />
                            </div>
                            <div className="layer-content">
                            <div className="row">
                                <div className="col-lg-6">
                                {/* ttm_single_image-wrapper */}
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src="../images/about/about-us-2.jpg" title="single-img-eight" alt="single-img-eight" />
                                </div>{/* ttm_single_image-wrapper end */}
                                <div className="about-overlay-02">
                                    <h3>20 Years of</h3>
                                    <p>Research Experience</p>
                                </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="res-991-mt-30">
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                    <div className="title-header">
                                        <h5>About IVR Survey</h5>
                                        <h2 className="title">We are dealing with the aspects of professional <span>IVR Research Method</span></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>We listen. We advise. We design, together. Happy customers and ongoing relationships are what we strive for. Success will be measured by the results, but also the most important thing is to make our clients feel satisfied with their experiences in IVR Survey.</p>
                                    </div>
                                    </div>{/* section title end */}
                                    <div className="mt-4">
                                        {/* progress-bar */}
                                        <div className="ttm-progress-bar">
                                            <div className="progress-bar-title float-left">IVR Method</div>{/* progress-bar-title */}
                                            <label className="float-right">99%</label>
                                            <MDBProgress className="w-100" material value={99}></MDBProgress>
                                        </div>
                                        {/* progress-bar END */}
                                        {/* progress-bar */}
                                        <div className="ttm-progress-bar">
                                            <div className="progress-bar-title float-left">SesWare Systems</div>{/* progress-bar-title */}
                                            <label className="float-right">95%</label>
                                            <MDBProgress className="w-100" material value={95}></MDBProgress>
                                        </div>
                                        {/* progress-bar END */}
                                        {/* progress-bar */}
                                        <div className="ttm-progress-bar">
                                            <div className="progress-bar-title float-left">Inbound/OutBound</div>{/* progress-bar-title */}
                                            <label className="float-right">90%</label>
                                            <MDBProgress className="w-100" material value={90}></MDBProgress>
                                        </div>
                                        {/* progress-bar END */}
                                        {/* progress-bar */}
                                        <div className="ttm-progress-bar">
                                            <div className="progress-bar-title float-left">Reporting</div>{/* progress-bar-title */}
                                            <label className="float-right">80%</label>
                                            <MDBProgress className="w-100" material value={80}></MDBProgress>
                                        </div>
                                        {/* progress-bar END */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                {/* progress-bar-section end */}
                {/* fid-section */}
                <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img10 mt_225 res-991-mt-0 clearfix">
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-md-3 col-6">
                        {/*  ttm-icon */}
                        <div className="ttm-fid inside ttm-fid-view-topicon">
                            <div className="ttm-fid-icon-wrapper">
                            <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                <img src='../images/icons/Countries-white.png' style={{width: 48 + 'px'}}></img>
                            </div>
                            </div>
                            <div className="ttm-fid-contents">{/*  ttm-fid-contents */}
                            <h4>
                                <CountUp start={0} end={6} duration={20} />
                            </h4>
                            <h3 className="ttm-fid-title">Countries</h3>{/*  ttm-fid-title */}
                            </div>{/* ttm-fid-contents end */}
                        </div>
                        </div>
                        <div className="col-md-3 col-6">
                        {/*  ttm-icon */}
                        <div className="ttm-fid inside ttm-fid-view-topicon">
                            <div className="ttm-fid-icon-wrapper">
                            <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                <img src='../images/icons/Completed-white.png' style={{width: 48 + 'px'}}></img>
                            </div>
                            </div>
                            <div className="ttm-fid-contents">{/*  ttm-fid-contents */}
                            <h4>
                                <CountUp start={0} end={70} duration={20} />
                            </h4>
                            <h3 className="ttm-fid-title">Completed Projects</h3>{/*  ttm-fid-title */}
                            </div>{/* ttm-fid-contents end */}
                        </div>
                        </div>
                        <div className="col-md-3 col-6">
                        {/*  ttm-icon */}
                        <div className="ttm-fid inside ttm-fid-view-topicon">
                            <div className="ttm-fid-icon-wrapper">
                            <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                <img src='../images/icons/Dialing-white.png' style={{width: 48 + 'px'}}></img>
                            </div>
                            </div>
                            <div className="ttm-fid-contents">{/*  ttm-fid-contents */}
                            <h4>
                                <CountUp start={0} end={1000000} duration={20} />
                            </h4>
                            <h3 className="ttm-fid-title">Dialing</h3>{/*  ttm-fid-title */}
                            </div>{/* ttm-fid-contents end */}
                        </div>
                        </div>
                        <div className="col-md-3 col-6">
                        {/*  ttm-icon */}
                        <div className="ttm-fid inside ttm-fid-view-topicon">
                            <div className="ttm-fid-icon-wrapper">
                            <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                                <img src='../images/icons/Survey-white.png' style={{width: 48 + 'px'}}></img>
                            </div>
                            </div>
                            <div className="ttm-fid-contents">{/*  ttm-fid-contents */}
                            <h4>
                                <CountUp start={0} end={100000} duration={20} />
                            </h4>
                            <h3 className="ttm-fid-title">Survey</h3>{/*  ttm-fid-title */}
                            </div>{/* ttm-fid-contents end */}
                        </div>
                        </div>
                    </div>
                    {/* row end*/}
                    </div>
                </section>
                {/* fid-section end */}
                {/* testimonial-section */}
                <section className="ttm-row only-testimonial-section bg-img11 clearfix">
                    <div className="container-xl">
                        <div className="row">{/* row */}
                            <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title with-desc text-center clearfix">
                                <div className="title-header">
                                    <h2 className="title">Check out our <span>Research Vision</span></h2>
                                </div>
                            </div>{/* section title end */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                            <Slider className="testimonial-slide style1 slick_slider pb-0" arrows={false} dots={false}>
                                <div className="testimonials text-center"> 
                                    <div className="testimonial-content">{/* testimonials-content */}
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">{/* testimonials-img */}
                                            <img className="img-center" alt="testimonial-img" src="../images/about/01-02.png" />
                                            </div>
                                        </div>
                                        <blockquote>A research need occurs when you want to deeply understand your customers and learn what they think about your company/products and at the point where you will take action accordingly.</blockquote>
                                    </div>
                                </div>
                                
                                <div className="testimonials text-center"> 
                                    <div className="testimonial-content">{/* testimonials-content */}
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">{/* testimonials-img */}
                                            <img className="img-center" alt="testimonial-img" src="../images/about/02-02.png" />
                                            </div>
                                        </div>
                                        <blockquote>The scope of the research and the sample are determined.<br/>- Customer satisfaction<br/>- service quality<br/>-company perception</blockquote>
                                    </div>
                                </div>
                                
                                <div className="testimonials text-center"> 
                                    <div className="testimonial-content">{/* testimonials-content */}
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">{/* testimonials-img */}
                                            <img className="img-center" alt="testimonial-img" src="../images/about/03-02.png" />
                                            </div>
                                        </div>
                                        <blockquote>Afterwards, a questionnaire is prepared. Audios of the questions prepared are recording and the questions are asked to the target audience in the same standard.</blockquote>
                                    </div>
                                </div>

                                <div className="testimonials text-center"> 
                                    <div className="testimonial-content">{/* testimonials-content */}
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">{/* testimonials-img */}
                                            <img className="img-center" alt="testimonial-img" src="../images/about/04-02.png" />
                                            </div>
                                        </div>
                                        <blockquote>The collected data is analyzed instantly (special analyzes can made depending on the data structure) and transferred to the dashboard panel.</blockquote>
                                    </div>
                                </div>

                                <div className="testimonials text-center"> 
                                    <div className="testimonial-content">{/* testimonials-content */}
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">{/* testimonials-img */}
                                            <img className="img-center" alt="testimonial-img" src="../images/about/05-02.png" />
                                            </div>
                                        </div>
                                        <blockquote>The results are shown comparatively in the desired periods and breakpoints. The aspects that need to be developed and action plan are determined accordingly.</blockquote>
                                    </div>
                                </div>
                                
                            </Slider>                      
                            </div>
                        </div>
                    </div>
                </section>
                {/* testimonial-section end */}
                {/* our-partner-section */}
                {/* <section className="ttm-row our-client-section res-991-p-0 ttm-bgcolor-skincolor clearfix">
                <div className="container-xl">
                    <div className="row">
                    <div className="col-12 text-center">
                        <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip" data-tooltip="client-01">
                                        <img className="img-fluid" src="../images/client/client-01.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip" data-tooltip="client-02">
                                        <img className="img-fluid" src="../images/client/client-02.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip" data-tooltip="client-03">
                                        <img className="img-fluid" src="../images/client/client-03.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip" data-tooltip="client-04">
                                        <img className="img-fluid" src="../images/client/client-04.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip" data-tooltip="client-05">
                                        <img className="img-fluid" src="../images/client/client-05.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Slider>
                    
                    </div>
                    </div>
                </div>
                </section> */}
                {/* our-partner-section end */}
                {/* blog-section */}
                {/* <section className="ttm-row blog-section clearfix">
                <div className="container-xl">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="section-title text-center with-desc clearfix">
                        <div className="title-header">
                            <h5>Our Blog</h5>
                            <h2 className="title">Check Out Our <span>Latest News</span></h2>
                        </div>
                        </div>
                    </div>
                    </div>
                    <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog style2">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/04.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">17<span className="entry-month entry-year">Apr</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>You Must Try 20 Secret Of Digital Transform</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />2,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog style2">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/05.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">12<span className="entry-month entry-year">Dec</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>Define World Best IT Solution Technology</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />3,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog style2">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/06.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">22<span className="entry-month entry-year">May</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>10 PHP Frameworks You Need To Use Anywhere</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />1,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog style2">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/07.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">04<span className="entry-month entry-year">Aug</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>Digital Conference Of IT Tech Events in 2019</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />4,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
                </section> */}
                {/* blog-section end */}
            </div>
            )
    }
}


export default Aboutus;