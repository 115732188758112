import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import { Footer } from './components/layout/Footer';
// pages
import Home from './components/Home';
import About from './components/pages/Aboutus';
import Features from './components/ivr/features';
import Usages from './components/ivr/usages';
import Technology from './components/ivr/technology';
import GDPR from './components/ivr/gdpr';
import Services from './components/pages/Services';
import Pricing from './components/pages/Pricing';
import Contact from './components/pages/Contact';
import Error from './components/pages/Error';
import ScrollToTop from './components/layout/Gotop';

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
function App() {
 
  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>  
        <div id="preloader">
          <div id="status">&nbsp;</div>
        </div>   
        <ScrollToTop />
            <Route exact path='/' component={ Home } /> 
            <Route path="/about" component={About} />
            <Route path="/error" component={Error} />
            <Route path="/features" component={Features} />
            <Route path='/usages' component={Usages} />
            <Route path='/technology' component={Technology} />
            <Route path='/gdpr' component={GDPR} />
            <Route path="/services" component={Services} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/contact" component={Contact} />
        <Footer />
    </Router>  
    </div> 
  );
 }

export default App;
