import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Header from '../layout/Header';

export class Usages extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row ttm-usages-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Usage Areas</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>Usage Areas</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area">
                                {/* ttm-service-single-content-are */}
                                {/* ttm-service-single-content-are */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper mb-35">
                                        <img className="img-fluid" src="../images/single-img-twelve.jpg" alt="single-img-twelve" />
                                    </div> */}
                                    <div className="ttm-service-description">
                                    <h3>Where can IVR Survey be used?</h3>
                                    <p>IVR Survey can be easily used as the most appropriate data capture method in all projects where speed and results are the focus. If you have a list of your own and you want to search this list according to your purpose, IVR Survey will be the most suitable structure for you.</p>
                                    
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">General Satisfaction Research</span>
                                            <p>Periodically measure the satisfaction of your customers quickly and stably</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Service Quality</span>
                                            <p>Reach the results of surveys with a short and not boring interview by calling the people who use your service once they finish with your system.</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Process Tracking</span>
                                            <p>You can inquire with a couple of questions to know whether the people who use your service have achieved the results or not. For example, you have a web portal for advertising a home for rent, don't you want to know if people can rent a home through them?</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Product/Service Adequacy</span>
                                            <p>You can directly learn the adequacy of your product sales and service points by calling your customers who buy products or use services.</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Brand Awareness</span>
                                            <p>You can measure your brand awareness by making nationwide calls with random digit dialing method.</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Brand Trustworthiness</span>
                                            <p>With questions about brand reliability, you can measure your customers' trust in your brand periodically or instantly.</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Lost Customer Research</span>
                                            <p>You can find out in a few questions , why your lost customers left you, how to win them back. You can set up your strategies accordingly.</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Performance Measurement</span>
                                            <p>You can find out the performance of the dealer, employee or distributor by calling the service recipients.</p>
                                        </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                            <span className="ttm-list-li-content">Help Other Sesearch Methods</span>
                                            <p>You can use it as an auxiliary data collection method in projects that continue with different methods.
                                                <br/>
                                                - Preliminary call in CATI projects
                                                <br/>
                                                - Scanning for AdHoc Projects
                                            </p>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default Usages;