import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li>
                        <a href={process.env.PUBLIC_URL + '/'}>Home</a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + '/about'}>About Us</a>
                    </li>
                    <li className="menu-hasdropdown"><a>IVRSurvey</a>
                        <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/features'}>Features</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/usages'}>Usage Areas</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/technology'}>Our Technology</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/gdpr'}>GDPR</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + '/services'}>Services</a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + '/pricing'}>Pricing</a>
                    </li>
                    <li>
                        <a href={process.env.PUBLIC_URL + '/contact'}>Contact Us</a>
                    </li>
                </ul>
            </nav>
    
            )
    }
}