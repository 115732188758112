import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Header from '../layout/Header';

export class gdpr extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row ttm-gdpr-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">GDPR</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>GDPR</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area">
                                {/* ttm-service-single-content-are */}
                                {/* ttm-service-single-content-are */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper mb-35">
                                        <img className="img-fluid" src="../images/single-img-twelve.jpg" alt="single-img-twelve" />
                                    </div> */}
                                    <div className="ttm-service-description">
                                    <h3>Security of Personal Data</h3>
                                    <p>Before GDPR rules, we gave utmost importance to data security. We were doing this for both the data security of our customers and our own ethical perspective.</p>
                                    <h5>There are 2 important personal data when conducting a survey with the IVR method.</h5>
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Phone number in the dial-up list</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Voice records as the results of an interview, only if you want some verbal answers on the questionnaire.</span></li>
                                    </ul>
                                    <p>The system we have established will allow us to receive full reports, on the other hand, it does not allow these personal data to be accessible in any way.</p>
                                    <p>We strictly separate the data transfer over the API, reporting, and IVR Dialer servers, as you can check below for the explanation :</p>
                                    <p>a. No data will be kept on the API Server. its only function is to transfer the incoming data directly to the IVR dialer server which is connected with a VPN.</p>
                                    <p>b.  We will only keep the answer code by the unique id that is provided by you. We will not keep the answers with the phone numbers on our servers. In this way, only the person who has this unique ID can match the interviewers' results.</p>
                                    <p>c. Our call center server is located in a secure area. Connection to the server is impossible without a VPN. Our other two servers also only can be connected with VPN to the IVR dialer server for WEB API communication. Even though we take all these precautions, we need your support for the mechanism to work properly. Although the time cost is a bit high in all projects, it is critical that you upload your data to our system using the Web API method.</p>
                                    </div>
                                </div>
                                <div className="ttm_single_image-wrapper mb-35 mt-35">
                                    <img className="img-fluid" src="../images/gdpr.jpg" />
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default gdpr;