import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
MDBHamburgerToggler, MDBIcon } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

class Mobilemenu extends Component {
state = {
  collapse1: false,
  collapseID: ''
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
}

toggleSingleCollapse = collapseId => {
  this.setState({
    ...this.state,
    [collapseId]: !this.state[collapseId]
  });
}

render() {
  return (
    <Router>
        <MDBNavbar>
            <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <MDBDropdown>
                      <MDBDropdownItem href={process.env.PUBLIC_URL + '/'} className='nav-link'><span>Home</span></MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownItem href={process.env.PUBLIC_URL + '/About'} className='nav-link'><span>About Us</span></MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownToggle nav>
                            <span>IVRSurvey</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/features'}>Features</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/usages'}>Usage Areas</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/technology'}>Our Technology</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/gdpr'}>GDPR</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownItem href={process.env.PUBLIC_URL + '/services'} className='nav-link'><span>Services</span></MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownItem href={process.env.PUBLIC_URL + '/pricing'} className='nav-link'><span>Pricing</span></MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownItem href={process.env.PUBLIC_URL + '/contact'} className='nav-link'><span>Contact Us</span></MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
          </MDBNavbar>
    </Router>
    );
  }
}

export default Mobilemenu;