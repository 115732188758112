import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer clearfix">
            {this.props.children}
                <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                <div className="container-xl">
                    <div className="row align-items-md-center">
                    <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
                        <div className="footer-logo text-sm-center">
                        <img data-src="images/footer-logo.png" className="img-fluid" alt="footer-logo" src="images/footer-logo.png" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
                        <div className="text-left">
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box left-icon icon-align-top">
                            <div className="featured-icon">{/*  featured-icon */} 
                            <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                                <i className="ti ti-location-pin" />{/*  ttm-icon */} 
                            </div>
                            </div>
                            <div className="featured-content">{/*  featured-content */} 
                            <div className="featured-desc">
                                <p>Altıntepe Mh. İstasyon Yolu Sk. No:3 34840 Maltepe/İstanbul</p>
                            </div>
                            </div>
                        </div>{/*  featured-icon-box END */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                        <div className="text-sm-right">
                        <a href="mailto:info@ivrsurvey.net" className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"><i className="far fa-envelope" /> info@ivrsurvey.net</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="second-footer ttm-textcolor-white bg-img2">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 widget-area">
                            <div className="widget widget_text  clearfix">
                            <h3 className="widget-title">About Our Company</h3>
                            <div className="textwidget widget-text">
                                We are the best world Information Technology Company. Providing the highest quality in hardware &amp; Network solutions. About more than 20 years of experience and 1000 of innovative achievements.
                                <br/><br/>
                                <div className="social-icons circle social-hover">
                                <ul className="list-inline">
                                    <li className="social-linkedin"><a className=" tooltip-top rounded-circle" target="_blank" href='https://www.linkedin.com/company/ivr-survey' data-tooltip="LinkedIn"><i className="fab fa-linkedin-in" /></a></li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 widget-area">
                        <div className="widget link-widget clearfix">
                            <h3 className="widget-title">Quick Links</h3>
                            <ul id="menu-footer-services">
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/features">Features</a></li>
                                <li><a href="/usages">Usage Areas</a></li>
                                <li><a href="/technology">Our Technology</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 widget-area">
                        <div className="widget link-widget clearfix">
                            <h3 className="widget-title">&nbsp;</h3>
                            <ul id="menu-footer-services">
                                <li><a href="/gdpr">GDPR</a></li>
                                <li><a href="/services">Services</a></li>
                                <li><a href="/pricing">Pricing</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="container-xl">
                    <div className="row copyright align-items-center res-767-text-center">
                        <div className="col-md-6">
                            <div>
                                <span>Copyright © 2022&nbsp;All rights reserved</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </footer>
        )
    }
}