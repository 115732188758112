import React, { Component } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const images = [
  '../images/portfolio/01.jpg',
  '../images/portfolio/02.jpg',
  '../images/portfolio/03.jpg',
  '../images/portfolio/04.jpg',
  '../images/portfolio/05.jpg',
  '../images/portfolio/06.jpg',
];

export class Home extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      const { photoIndex, isOpen } = this.state;
        var slick_slider = {
          dots: false,
          arrow: true,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        
        return (       
          <div className="site-main">   
            <Header/>            
            {/* home banner */}
            <CarouselPage/>{/* home banner end*/}            
            {/* service-section */}     
            <section className="ttm-row service-section position-relative z-1 clearfix">
              <div className="container-xl">
                <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
                  <div className="col-md-4">
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          {/*<i className="flaticon flaticon-analytics" />  ttm-icon */}
                          <img src='../images/fast-icon.png' style={{width: 56 + 'px'}}></img>
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>Fast</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>Hundreds of surveys at once</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                          {/*<i className="flaticon flaticon-clock" />  ttm-icon */} 
                          <img src='../images/easy-icon.png' style={{width: 56 + 'px'}}></img>
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>Easy</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>Data can be easily tracked</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          {/*<i className="flaticon flaticon-developer" />  ttm-icon */} 
                          <img src='../images/secure-icon.png' style={{width: 56 + 'px'}}></img>
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>Secure</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>High security for your data privacy</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                </div>
              </div>
            </section>
            {/* service-section end */}
            {/* aboutus-section */}
            <section className="ttm-row aboutus-section clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-6">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>About us</h5>
                        <h2 className="title">We are here for IVR Solution with years of <span>experiences</span></h2>
                      </div>
                      <div className="title-desc">
                        <p>We have been developing a lot of software in different research companies since 2003. We met in 2019 for another new challenge, and here we are for IVR Survey</p>
                        <p>Half of the team became experts in research report systems and the other half in telephone-assisted survey systems. Professional researchers have been added to this team and IVR survey is ready for the services.</p>
                        <p>We continue our way with the awareness of the system, which has been completed in about 1.5 years now. This is just the beginning of our journey.</p>
                      </div>
                    </div>{/* section title end */}
                    {/* row */}
                    <div className="row no-gutters mt-4">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <h5>Research Solutions</h5>
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Expectation and satisfaction research </span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Perception research</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Social support evaluation research</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Agenda (National / Local) research</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Election Campaign, Pre- and Post-Poll Studies</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Brand  &amp; service positioning research</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Brand /Service preference determinations</span></li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <h5>Sector Experience</h5>
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Banks</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Insurance</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Council</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Public establishment</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Health Care</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Education</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">E-commercial</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Food</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Automotive</span></li>
                        </ul>
                      </div>
                    </div>{/* row END*/}
                    {/*                     
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    <div className="fs-16 ttm-textcolor-darkgrey">
                      <span>Call to ask <a className="fw-500 ttm-textcolor-darkgrey"><u>any question</u></a></span>
                      <strong className="fs-18">: 123-456-7890</strong> or <strong className="fs-18">456-789-1430</strong>
                    </div> */}
                  </div>
                  <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper">
                        <img className="img-fluid" src="../images/about_us.png" title="single-img-one" alt="single-img-one" />
                      </div>{/* ttm_single_image-wrapper end */}
                      {/*featured-icon-box*/}
                      <div className="about-overlay-01">
                        <p className="mb-0">IVRSurvey.net <br/>Fast &amp; Easy &amp; Secure</p>
                      </div>
                      {/* featured-icon-box end*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* aboutus-section end */}
            {/* history-section */}
            <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {/* section title */}
                    <div className="section-title with-desc text-center clearfix">
                      <div className="title-header">
                        <h5>What can we evaluate?</h5>
                        <h2 className="title">We run all kinds of services in form of <br/> <span>IVR Survey</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider}>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              {/*<i className="flaticon flaticon-business-and-finance" />  ttm-icon */} 
                              <img src='../images/icons/01_orange.png' style={{width: 40 + 'px', marginLeft: 15 + 'px', marginTop: 15 + 'px'}}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>General Satisfaction</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        Periodically measure the satisfaction of your customers quickly and stably
                        </div>
                        <a href="/usages" className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              {/*<i className="flaticon flaticon-computer" />{/*  ttm-icon */} 
                              <img src='../images/icons/02_orange.png' style={{width: 40 + 'px', marginLeft: 15 + 'px', marginTop: 15 + 'px'}}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Service Quality</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        Reach the results of the surveys with a short and not boring interview by calling the people who used your service shortly after the service is used.
                        </div>
                        <a href="/usages" className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              {/*<i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                              <img src='../images/icons/03_orange.png' style={{width: 40 + 'px', marginLeft: 15 + 'px', marginTop: 15 + 'px'}}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Process Tracking</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        You can inquire with a couple of questions whether the people who used service from you have achieved results.
                        </div>
                        <a href="/usages" className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              {/*<i className="flaticon flaticon-global-1" />{/*  ttm-icon */} 
                              <img src='../images/icons/04_orange.png' style={{width: 40 + 'px', marginLeft: 15 + 'px', marginTop: 15 + 'px'}}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Product/Service Adequacy</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        You can directly learn the adequacy of your product sales and service points by calling your customers who buy products or use services.
                        </div>
                        <a href="/usages" className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              {/*<i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */} 
                              <img src='../images/icons/05_orange.png' style={{width: 40 + 'px', marginLeft: 15 + 'px', marginTop: 15 + 'px'}}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Brand Awareness</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        You can measure your brand awareness by making nationwide calls with random digit dialing method.
                        </div>
                        <a href="/usages" className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              {/*<i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */} 
                              <img src='../images/icons/06_orange.png' style={{width: 40 + 'px', marginLeft: 15 + 'px', marginTop: 15 + 'px'}}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Brand Trustworthiness</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        With questions about brand reliability, you can measure your customers' trust in your brand periodically or instantly.
                        </div>
                        <a href="/usages" className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                </Slider>
                
                <div className="row">
                  <div className="col-md-12 text-center mt-5 res-991-mt-30">
                    <p className="mb-0">Don’t hesitate to contact us for better help and services <strong><u><a href="/usages" className="ttm-textcolor-darkgrey"> View more Services</a></u></strong></p>
                  </div>
                </div>
              </div>
            </section>
            {/* history-section end*/}
            {/* zero-padding-section */}
            <section className="ttm-row zero-padding-section mt_80 res-991-mt-0 clearfix">
              <div className="container-xl">
                <div className="row no-gutters">
                  <div className="col-lg-6">
                    {/* col-bg-img-one */}
                    <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                      </div>
                    </div>
                    {/* Responsive View image */}
                    <img src="../images/bg-image/why_ivr_survey.jpg" className="ttm-equal-height-image img-fluid" alt="col-bgimage-1" />
                  </div>
                  <div className="col-lg-6 z-9">
                    <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                      <div className="spacing-1">
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                          <div className="title-header">
                            <h5>Why IVR Survey</h5>
                            <h2 className="title">We deal with the aspects of professional <span>IVR Research Method</span></h2>
                          </div>
                          <div className="title-desc">
                            <p>Our IT services converge business and technology experts to manage business processes of all categories.</p>
                          </div>
                        </div>{/* section title end */}
                        <div className="row mt-40">
                          <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                              <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                                <img src='../images/icons/experience-white.png' style={{width: 46 + 'px', marginLeft: 6 + 'px'}}></img>
                                </div>
                              </div>
                              <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                  <h5>Experienced team</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                  <p>We have been developing systems on telephone-assisted survey systems for over 20 years.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* separator */}
                        <div className="separator">
                          <div className="sep-line mt-4 mb-4" />
                        </div>
                        {/* separator */}
                        <div className="row">
                          <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                              <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                                <img src='../images/icons/practical-white.png' style={{width: 46 + 'px', marginLeft: 6 + 'px'}}></img>
                                </div>
                              </div>
                              <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                  <h5>Practical is the key</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                  <p>We specifically can produce practical solutions for your projects. We enjoy the process from the beginning and adding your requests to our system.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* separator */}
                        <div className="separator">
                          <div className="sep-line mt-4 mb-4" />
                        </div>
                        {/* separator */}
                        <div className="row">
                          <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                              <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                                <img src='../images/icons/development-white.png' style={{width: 46 + 'px', marginLeft: 6 + 'px'}}></img>
                                </div>
                              </div>
                              <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                  <h5>We are open to the development</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                  <p>We believe in the words of "There are no user errors, there are user habits that the programmer can't predict"</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* zero-padding-section end */}
            {/* team-section */}
            <section className="ttm-row team-section res-991-pt-0 clearfix">
              <div className="container-xl">
                <div className="row align-items-center mb-5">
                  <div className="col-lg-5 res-991-text-center">
                    <div className="section-title style2 mb-0 clearfix">
                      <div className="title-header mb-0">
                        <h5>About us</h5>
                        <h2 className="title">Our <span>Vision</span></h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 res-991-text-center">
                    <div className="title-desc"></div>
                  </div>
                  <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
                    <a href="/about" className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey"> Read More </a>
                  </div>
                </div>
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-team style1">
                      <div className="featured-thumbnail">{/* featured-thumbnail */}
                        <img className="img-fluid" src="../images/home/01.png" alt="" />
                      </div> 
                      <div className="featured-content box-shadow">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Needs</a></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-team style1">
                      <div className="featured-thumbnail">{/* featured-thumbnail */}
                        <img className="img-fluid" src="../images/home/02.png" alt="" />
                      </div> 
                      <div className="featured-content box-shadow">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Research Design</a></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-team style1">
                      <div className="featured-thumbnail">{/* featured-thumbnail */}
                        <img className="img-fluid" alt="" src="../images/home/03.png" />
                      </div> 
                      <div className="featured-content box-shadow">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Questionnare Form</a></h5>
                        </div>
                        {/* <p className="category">Consultant Officer</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-team style1">
                      <div className="featured-thumbnail">{/* featured-thumbnail */}
                        <img className="img-fluid" alt="" src="../images/home/04.png" />
                      </div> 
                      <div className="featured-content box-shadow">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Data Analysis</a></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-team style1">
                      <div className="featured-thumbnail">{/* featured-thumbnail */}
                        <img className="img-fluid" alt="" src="../images/home/05.png" />
                      </div> 
                      <div className="featured-content box-shadow">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Information</a></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </section>
            {/* team-section end*/}
            {/* first-row-title-section */}
            <section className="ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes bg-img4 clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">{/* row */}
                  <div className="col-lg-8 offset-lg-2">
                    <div className="text-center">
                      {/* <div className="ttm-play-icon-btn mb-5">
                        <div className="ttm-play-icon-animation">
                          <a id="playbutton">
                            <div className="ttm-icon ttm-bgcolor-white ttm-textcolor-skincolor ttm-icon_element-size-sm rounded-circle">
                              <i className="fas fa-play" />
                            </div>                            
                          </a>
                          <div id="videobox">
                            <i id="close-btn" className="fa fa-times"></i>
                            <div id="video-wrapper">
                              <iframe width="500" height="500" src="https://www.youtube.com/embed/7e90gBu4pas" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>                              
                            </div>
                          </div>           
                        </div>
                      </div> */}
                      {/* section-title */}
                      <div className="section-title with-desc text-center clearfix">
                        <div className="title-header">
                          <h2 className="title">IVR Survey is the most affordable survey system you can do by call</h2>
                        </div>
                        <div className="title-desc">In addition to “fast”, “easy” and “security”, <br/>we offer you an incredible price advantage</div>
                      </div>{/* section-title end */}
                      <div className="mt-5">
                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white" href='/pricing'>Read More</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* first-row-title-section end*/}
            {/* testimonial-section */}
            {/* <section className="ttm-row zero-padding-section clearfix">
              <div className="container-xl">
                <div className="row no-gutters mt_100 res-991-mt-0">
                  <div className="col-md-6">
                    <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                      <div className="layer-content">
                        <div className="spacing-2">
                          <div className="section-title ttm with-desc clearfix">
                            <div className="title-header">
                              <h5>What We Do</h5>
                              <h2 className="title">We help you to grow your business <span className="ttm-textcolor-white">Exponentially</span></h2>
                            </div>
                          </div>
                          <div className="ttm-progress-bar">
                            <div className="progress-bar-title float-left">UI/UX Design</div>
                            <label className="float-right">90%</label>
                            <MDBProgress className="w-100" material value={90}></MDBProgress>
                          </div>
                          <div className="ttm-progress-bar">
                            <div className="progress-bar-title float-left">App Development</div>
                            <label className="float-right">80%</label>
                            <MDBProgress className="w-100" material value={80}></MDBProgress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                      </div>
                      <div className="layer-content">
                        <div className="testimonial-slide style1">
                          <Slider className="row slick_slider" arrows={false} dots={true}>
                            <div className="col-lg-12">
                              <div className="testimonials text-center"> 
                                <div className="testimonial-content">
                                  <div className="testimonial-avatar">
                                    <div className="testimonial-img">
                                      <img className="img-center" alt="testimonial-img" src="../images/testimonial/01.jpg" />
                                    </div>
                                  </div>
                                  <blockquote>Our team discussed every single detail to make sure Creatives Planet is the most versatile and unique theme created so far. No coding skills.</blockquote>
                                  <div className="ttm-ratting-star">
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />
                                  </div>
                                  <div className="testimonial-caption">
                                    <h6>Victor Williamson</h6>
                                    <label>Web Devloper</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="testimonials text-center"> 
                                <div className="testimonial-content">
                                  <div className="testimonial-avatar">
                                    <div className="testimonial-img">
                                      <img className="img-center" src="../images/testimonial/02.jpg" alt="testimonial-img" />
                                    </div>
                                  </div>
                                  <blockquote>Our team discussed every single detail to make sure Creatives Planet is the most versatile and unique theme created so far. No coding skills.</blockquote>
                                  <div className="ttm-ratting-star">
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />
                                  </div>
                                  <div className="testimonial-caption">
                                    <h6>Victor Williamson</h6>
                                    <label>Web Devloper</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="testimonials text-center"> 
                                <div className="testimonial-content">
                                  <div className="testimonial-avatar">
                                    <div className="testimonial-img">
                                      <img className="img-center" alt="testimonial-img" src="../images/testimonial/03.jpg" />
                                    </div>
                                  </div>
                                  <blockquote>Our team discussed every single detail to make sure Creatives Planet is the most versatile and unique theme created so far. No coding skills.</blockquote>
                                  <div className="ttm-ratting-star">
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />
                                  </div>
                                  <div className="testimonial-caption">
                                    <h6>Victor Williamson</h6>
                                    <label>Web Devloper</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* testimonial-section end */}
            {/* process-section */}
            <section className="ttm-row process-section clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-md-12">
                    {/* section title */}
                    <div className="section-title text-center with-desc clearfix">
                      <div className="title-header">
                        <h5>how it work</h5>
                        <h2 className="title">Check Out Our Work <span>Process</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>
                {/* row */}
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={4}>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-one" title="step-one" src="../images/how/01.png" />
                          <div className="process-num">
                            <span className="number">01</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>Prepare List</h5></div> */}
                          <div className="ttm-box-description">Preparation of customer list and transfer with WEB API</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-two" title="step-two" src="../images/how/02.png" />
                          <div className="process-num">
                            <span className="number">02</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>Transfer List</h5></div> */}
                          <div className="ttm-box-description">Save the list to the IVR server without saving it anywhere</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-three" title="step-three" src="../images/how/03.png" />
                          <div className="process-num">
                            <span className="number">03</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>IVR Calls</h5></div> */}
                          <div className="ttm-box-description">IVR calls are made</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-four" title="step-four" src="../images/how/04.png" />
                          <div className="process-num">
                            <span className="number">04</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>Data Collection</h5></div> */}
                          <div className="ttm-box-description">Answers are collected in the database</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-four" title="step-four" src="../images/how/05.png" />
                          <div className="process-num">
                            <span className="number">05</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>Save Data</h5></div> */}
                          <div className="ttm-box-description">Answers are transferred to the IVR database</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-four" title="step-four" src="../images/how/06.png" />
                          <div className="process-num">
                            <span className="number">06</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>Data Process</h5></div> */}
                          <div className="ttm-box-description">Data is synchronized and anonymized</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-four" title="step-four" src="../images/how/07.png" />
                          <div className="process-num">
                            <span className="number">07</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>Export Results</h5></div> */}
                          <div className="ttm-box-description">Call results are exported to DASHBOARD</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-four" title="step-four" src="../images/how/08.png" />
                          <div className="process-num">
                            <span className="number">08</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          {/* <div className="featured-title"><h5>Dashboard</h5></div> */}
                          <div className="ttm-box-description">Research results are shown on screens</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>{/* row end */}
              </div>
            </section>
            {/* portfolio-section */}
            <section className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl pb-5 res-991-pb-0">
                <div className="row align-items-center ttm-textcolor-white">
                  <div className="col-lg-5 res-991-text-center">
                    <div className="section-title style3 mb-0 clearfix">
                      <div className="title-header mb-0">
                        <h2 className="title">News from <span className="ttm-textcolor-white">Us</span></h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 res-991-text-center">
                    <div className="title-desc">A project is a temporary endeavor with a defined beginning and end, scope, and assigned resources.</div>
                  </div>
                  <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
                    <a href="/about" className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"> Read More </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="ttm-row mt-10 zero-padding-section clearfix">
              <div className="container-fluid p-0">
                {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )}
                <Slider className="row slick_slider ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={5}>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/portfolio/01.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                          <a onClick={() => this.setState({ isOpen: true })} className="ttm_image"><i className="ti ti-search" /></a>
                          <a href={process.env.PUBLIC_URL + '/about'} className="ttm_link"><i className="ti ti-link" /></a>
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Business Seminarsetwst</a></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/portfolio/02.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                          <a onClick={() => this.setState({ isOpen: true })}><i className="ti ti-search" /></a>
                          <a href={process.env.PUBLIC_URL + '/about'} className="ttm_link"><i className="ti ti-link" /></a>
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>IT Management</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/portfolio/03.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                          <a onClick={() => this.setState({ isOpen: true })}><i className="ti ti-search" /></a>
                          <a href={process.env.PUBLIC_URL + '/about'} className="ttm_link"><i className="ti ti-link" /></a>
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Cyber Security Analysis</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/portfolio/04.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                          <a onClick={() => this.setState({ isOpen: true })}><i className="ti ti-search" /></a>
                          <a href={process.env.PUBLIC_URL + '/about'} className="ttm_link"><i className="ti ti-link" /></a>
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Neural Networking</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/portfolio/05.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                          <a onClick={() => this.setState({ isOpen: true })}><i className="ti ti-search" /></a>
                          <a href={process.env.PUBLIC_URL + '/about'} className="ttm_link"><i className="ti ti-link" /></a>
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Data Management</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/portfolio/06.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                          <a onClick={() => this.setState({ isOpen: true })}><i className="ti ti-search" /></a>
                          <a href={process.env.PUBLIC_URL + '/about'} className="ttm_link"><i className="ti ti-link" /></a>
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a href={process.env.PUBLIC_URL + '/about'}>Web Devlopment</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                </Slider>
              </div>
            </section>
            {/* process-section end */}
            {/* blog-section */}
            {/* <section className="ttm-row blog-section res-991-pt-0 clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title text-center with-desc clearfix">
                      <div className="title-header">
                        <h5>Our Blog</h5>
                        <h2 className="title">Check Out Our <span>Latest News</span></h2>
                      </div>
                    </div>
                  </div>
                </div>
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/04.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">17<span className="entry-month entry-year">Apr</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>You Must Try 20 Secret Of Digital Transform</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />2,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                        <div className="featured-desc">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...</p>
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" href={process.env.PUBLIC_URL + '/Single_blog'}>Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/05.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">12<span className="entry-month entry-year">Dec</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>Define World Best IT Solution Technology</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />3,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                        <div className="featured-desc">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...</p>
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" href= {process.env.PUBLIC_URL + '/Single_blog'}>Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/06.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">22<span className="entry-month entry-year">May</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>10 PHP Frameworks You Need To Use Anywhere</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />1,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                        <div className="featured-desc">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...</p>
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" href= {process.env.PUBLIC_URL + '/Single_blog'}>Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-blog">
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="../images/blog/07.jpg" />
                        <div className="ttm-blog-overlay-iconbox">
                          <a href={process.env.PUBLIC_URL + '/Single_blog'}><i className="ti ti-plus" /></a>
                        </div>
                        <div className="ttm-box-view-overlay" />
                      </div>
                      <div className="featured-content">
                        <div className="ttm-box-post-date">
                          <span className="ttm-entry-date">
                            <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">04<span className="entry-month entry-year">Aug</span></time>
                          </span>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + '/Single_blog'}>Digital Conference Of IT Tech Events in 2019</a></h5>
                        </div>
                        <div className="post-meta">
                          <span className="ttm-meta-line"><i className="fa fa-comments" />4,comments</span>
                          <span className="ttm-meta-line"><i className="fa fa-user" />Admin</span>
                        </div>
                        <div className="featured-desc">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...</p>
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" href={process.env.PUBLIC_URL + '/Single_blog'}>Read More <i className="ti ti-angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </section> */}
            {/* blog-section end */}
          </div>
        )
    }
}


export default Home;
