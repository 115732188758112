import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Header from '../layout/Header';

const customCSS = {
    marginLeft: "10px",
    marginTop: "15px"
}

const customAlignCenter = {
    alignItems: "center"
}

export class Methodology extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row ttm-technology-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title text-white">Our Technology</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" className='text-white' href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep text-white">&nbsp; : : &nbsp;</span>
                            <span className=' text-white'>Our Technology</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area">
                                {/* ttm-service-single-content-are */}
                                {/* ttm-service-single-content-are */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper mb-35">
                                    <img className="img-fluid" src="../images/single-img-twelve.jpg" alt="single-img-twelve" />
                                    </div> */}
                                    <div className="ttm-service-description">
                                        <h3>Our Technology</h3>
                                        <p>Development is continuous, it is inevitable, especially when it comes to technology. The system we established 20 years ago with dial-up modems and analog circuits in the sound card has evolved into a completely different place with the big leap in technology today.</p>
                                        <p>The development will continue. With this in mind, we take uttermost care to establish a structure that will include renewable and modern technologies at any time.</p>
                                        <p>Our goal is to add avant-garde innovations to our system features every day.</p>
                                        <p>Improvement arises from the need, we do aware of this. For this reason, we will consider the requests from our customers not as hard work that needs to be done, we get them as ideas that will support the development of our system.</p>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                        </div>{/* row end */}
                        <div className='row mb-35'>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginRight: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/01-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>IVR is the most advanced interactive voice response system. Participants answer their questionnaires by choosing the most appropriate one among the given options.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginLeft: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/02-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                    <p style={{...customCSS}}>With IVR, the target audience will be easily reached by using the latest technology.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginRight: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/03-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>Since thousands of participants can be called at the same time, the target audience will be called in the fastest way.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginLeft: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/04-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>With pre-created audio recordings, the questions are read and everyone called is allowed to listen to the questions in the same standard.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginRight: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/05-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>The additional module will be added from the respondents using speech recognition technology.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginLeft: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/06-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>The voice message will be added at the end of the survey, and the intended message will be sent to participants.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginRight: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/07-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>It can be integrated into other data collection methods.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginLeft: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/08-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>Its cost is much lower than other research methods.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex mt-35'>
                                <div className='row' style={{border: '1px solid rgb(238, 243, 246)', borderRadius: 5 + 'px', marginRight: '10px'}}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{display: 'flex', textAlign: 'center', margin: '15px', padding: '0', backgroundColor: '#EEF3F6'}}>
                                        <img src="./images/technology/09-02.png" style={{maxWidth: '100%', padding: '10px'}}></img>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>
                                        <p style={{...customCSS}}>It can make a wide range of calls from 10,000 to 500,000 per day.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}


export default Methodology;