import React, { Component } from 'react';
import { fadeIn } from 'react-animations'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import Header from '../layout/Header';

export class Pricing extends Component {
    render() {

        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row ttm-pricing-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Pricing</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>Pricing</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <section className="ttm-row faqs-section clearfix">
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {/* ttm_single_image-wrapper 
                            <div className="ttm_single_image-wrapper">
                                <img className="img-fluid" src="../images/single-img-eleven.jpg" title="single-img-eleven" alt="single-img-eleven" />
                            </div> ttm_single_image-wrapper end */}
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-35">
                            {/* accordian */}
                            <Accordion preExpanded={['foo']}>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Our prices for UK
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <form className="ttm-cart-form">
                                            <table className="shop_table shop_table_responsive">
                                                <thead>
                                                    <tr>
                                                        <th className="product-name">Service / Product</th>
                                                        <th className="product-mainprice">Price</th>
                                                        <th className="product-quantity">Quantity</th>
                                                        <th className="product-subtotal">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Project Setup</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">£</span>1000.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">£</span>1000.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Call Rate Per Minute</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">£</span>0.22</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1000</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">£</span>220.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="text-red">free
                                                            </span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b className='text-red'>free</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard Pro</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">£</span>1150.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">£</span>1150.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Audio Recording</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">£</span>200.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">£</span>200.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Manual Import/Export Data</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">£</span>100.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">£</span>100.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                    
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Import)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">£</span>200.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">£</span>200.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Export)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">£</span>100.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">£</span>100.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </form>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Our prices for Germany
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <form className="ttm-cart-form">
                                            <table className="shop_table shop_table_responsive">
                                                <thead>
                                                    <tr>
                                                        <th className="product-name">Service / Product</th>
                                                        <th className="product-mainprice">Price</th>
                                                        <th className="product-quantity">Quantity</th>
                                                        <th className="product-subtotal">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Project Setup</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>1100.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>1100.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Call Rate Per Minute</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>0.103</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1000</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>103.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="text-red">
                                                            free</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="text-red">
                                                                <b>free</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard Pro</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>1250.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>1250.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Audio Recording</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>250.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>250.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Manual Import/Export Data</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>125.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>125.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Import)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>250.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>250.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Export)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>125.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>125.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </form>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Our prices for France
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <form className="ttm-cart-form">
                                            <table className="shop_table shop_table_responsive">
                                                <thead>
                                                    <tr>
                                                        <th className="product-name">Service / Product</th>
                                                        <th className="product-mainprice">Price</th>
                                                        <th className="product-quantity">Quantity</th>
                                                        <th className="product-subtotal">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Project Setup</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>1100.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>1100.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Call Rate Per Minute</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>0.155</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1000</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>155.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="text-red">
                                                            free</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="text-red">
                                                                <b>free</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard Pro</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>1250.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>1250.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Audio Recording</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>250.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>250.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Manual Import/Export Data</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>125.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>125.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Import)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>250.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>250.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Export)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">€</span>125.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">€</span>125.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </form>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Our prices for Indonesia
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <form className="ttm-cart-form">
                                            <table className="shop_table shop_table_responsive">
                                                <thead>
                                                    <tr>
                                                        <th className="product-name">Service / Product</th>
                                                        <th className="product-mainprice">Price</th>
                                                        <th className="product-quantity">Quantity</th>
                                                        <th className="product-subtotal">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Project Setup</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">$</span>1200.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">$</span>1200.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Call Rate Per Minute</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">$</span>0.13</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1000</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">$</span>130.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="text-red">
                                                            free</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="text-red">
                                                                <b>free</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Dashboard Pro</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">$</span>1400.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">$</span>1400.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Audio Recording</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">$</span>300.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">$</span>300.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">Manual Import/Export Data</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">$</span>150.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">$</span>150.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Import)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">$</span>300.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">$</span>300.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="product-name" data-title="Product">
                                                            <span className="product-name">API Integration (Export)</span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className='text-red'>optional</span>
                                                        </td>
                                                        <td className="product-mainprice" data-title="Price">
                                                            <span className="Price-amount">
                                                            <span className="Price-currencySymbol">$</span>150.00</span>
                                                        </td>
                                                        <td className="product-quantity" data-title="Quantity">
                                                            <span>1</span>
                                                        </td>
                                                        <td className="product-subtotal" data-title="Total">
                                                            <span className="Price-amount">
                                                                <b><span className="Price-currencySymbol">$</span>150.00</b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </form>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                    {/* row end*/}
                    </div>
                </section>
            </div>
            )
    }
}


export default Pricing;