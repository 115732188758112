import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../layout/Header';

export class Services extends Component {
    render() {
        var slick_slider = {
          dots: false,
          arrow: true,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row ttm-services-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Our Services</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>Our Services</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                {/* about-services-section */}
                <section className="ttm-row about-services-section clearfix">
                    <div className="container-xl">
                    <div className="row align-items-center">{/* row */}
                        <div className="col-lg-6">
                        <div className="position-relative">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper text-sm-center">
                            <img className="img-fluid" src="../images/services/our-services.jpg" title="single-img-ten" alt="single-img-ten" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="res-991-mt-30">
                            {/* section title */}
                            <div className="section-title with-desc clearfix">
                            <div className="title-header">
                                <h5>Our Services</h5>
                                <h2 className="title">Give it a try and get the benefit of using<br /> IVR <span> Surveys</span></h2>
                            </div>
                            <div className="title-desc">
                                <p>Would you like to get the improvement of your surveys from the services we offer to you?</p>
                            </div>
                            </div>{/* section title end */}
                            {/* row */}
                            <div className="row mt-25">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box iconalign-before-heading">
                                <div className="featured-content">{/* featured-content */}
                                    <div className="ttm-icon ttm-bgcolor-grey style1">
                                    <i className="ti ti-info ttm-num" />
                                    </div>
                                    <div className="featured-title">{/* featured-title */}
                                    <h5>Market Research Consultant</h5>
                                    </div>
                                </div>
                                <div className="featured-desc">{/* featured-desc */}
                                    <p>Let's prepare the questionnaires for your project together with our expert team. We will determine your project's dial algorithms with our IVR's experiences for more than 20 years, and you will get great results from the dashboard.</p>
                                </div>
                                </div>{/* featured-icon-box */}
                            </div>
                            </div>
                            {/* separator */}
                            <div className="separator">
                            <div className="sep-line mt-4 mb-3" />
                            </div>
                            {/* separator */}
                            <div className="row">
                            <div className="col-12">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box iconalign-before-heading">
                                <div className="featured-content">{/* featured-content */}
                                    <div className="ttm-icon ttm-bgcolor-grey style1">
                                    <i className="ti ti-info ttm-num" />
                                    </div>
                                    <div className="featured-title">{/* featured-title */}
                                    <h5>Technologic Consultant</h5>
                                    </div>
                                </div>
                                <div className="featured-desc">{/* featured-desc */}
                                    <p>API Integrations : Let's make your integrations using the latest technologies in data transfers.</p>
                                    <p>Innovation: Let's implement improvements that have never been made before, only as per your requests.</p>
                                </div>
                                </div>{/* featured-icon-box */}
                            </div>
                            </div>
                            {/*
                            <div className="separator">
                            <div className="sep-line mt-4 mb-3" />
                            </div>
                            <div className="row">
                            <div className="col-12">
                                <div className="featured-icon-box iconalign-before-heading">
                                <div className="featured-content">
                                    <div className="ttm-icon ttm-bgcolor-grey style1">
                                    <i className="ti ti-info ttm-num" />
                                    </div>
                                    <div className="featured-title">
                                    <h5>Reporting &amp; Insight</h5>
                                    </div>
                                </div>
                                <div className="featured-desc">
                                    <p>Lorem Ipsum is simply dummy textover of the printing and typesetting industryin. printing and typesetting industry.</p>
                                </div>
                                </div>
                            </div>
                            </div> */}
                        </div>
                        </div>
                    </div>
                    {/* row end */}
                    </div>
                </section>
                {/* about-services-section end */}
                {/* services-slide-section */}
                <section className="ttm-row services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
                    <div className="container-xl">                        
                        <div className="row align-items-center">{/* row */}
                            <div className="col-lg-12 col-xl-12 col-md-12">
                            {/* section-title */}
                            <div className="section-title style2 clearfix">
                                <div className="title-header">
                                {/* <h5>Fast Growing Information &amp; technologies Estates.</h5> */}
                                <h2 className="title">Our <span>Workflow!</span></h2>
                                </div>
                            </div>{/* section-title end */}
                            </div>
                        </div>
                        <Slider className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
                            <div className="ttm-box-col-wrapper">
                                {/* featured-imagebox-services */}
                                <div className="featured-imagebox featured-imagebox-services style1">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                    <img className="img-fluid" src="../images/services/01.jpg" alt="image" />
                                    </div> 
                                    <div className="featured-content box-shadow">
                                    <div className="featured-title">{/* featured-title */}
                                        <h5>Project Setup</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured-title */}
                                        <p>- Preparation of customer list and transfer with WEB API</p>
                                        <p>- Exports the list to the IVR server without saving it anywhere</p>
                                    </div>
                                    </div>
                                </div>
                                {/* featured-imagebox-services */}
                            </div>
                            <div className="ttm-box-col-wrapper">
                                {/* featured-imagebox-services */}
                                <div className="featured-imagebox featured-imagebox-services style1">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                    <img className="img-fluid" src="../images/services/03.jpg" alt="image" />
                                    </div> 
                                    <div className="featured-content box-shadow">
                                    <div className="featured-title">{/* featured-title */}
                                        <h5>Data Collection</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured-title */}
                                        <p>- IVR calls are made</p>
                                        <p>- Answers are collected in the database</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* featured-imagebox-services */}
                            <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-services style1">
                                <div className="featured-thumbnail">{/* featured-thumbnail */}
                                <img className="img-fluid" src="../images/services/02.jpg" alt="image" />
                                </div> 
                                <div className="featured-content box-shadow">
                                <div className="featured-title">{/* featured-title */}
                                    <h5>Data Transfer</h5>
                                </div>
                                <div className="featured-desc">{/* featured-title */}
                                    <p>- Answers are transferred to the IVR database</p>
                                    <p>- Data is synchronized and anonymized</p>
                                </div>
                                </div>
                            </div>
                            {/* featured-imagebox-services */}
                            </div>
                            {/* featured-imagebox-services */}
                            <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-services style1">
                                <div className="featured-thumbnail">{/* featured-thumbnail */}
                                <img className="img-fluid" src="../images/services/04.jpg" alt="image" />
                                </div> 
                                <div className="featured-content box-shadow">
                                <div className="featured-title">{/* featured-title */}
                                    <h5>Reporting</h5>
                                </div>
                                <div className="featured-desc">{/* featured-title */}
                                    <p>- Call results are exported to DASHBOARD</p>
                                    <p>- Research results are shown on screens</p>
                                </div>
                                </div>
                            </div>
                            {/* featured-imagebox-services */}
                            </div>
                        </Slider>
                    </div>
                </section>
                {/* services-slide-section end */}
            </div>
            )
    }
}

export default Services;