import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Header from '../layout/Header';

export class Features extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row ttm-features-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Features</h1>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                            <span>Features</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area">
                                {/* ttm-service-single-content-are */}
                                {/* ttm-service-single-content-are */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper mb-35">
                                        <img className="img-fluid" src="../images/single-img-twelve.jpg" alt="single-img-twelve" />
                                    </div> */}
                                    <div className="ttm-service-description">
                                    <h3>IVR Survey Features</h3>
                                    <p>With IVR Survey, we plan to minimize human touches in your research. Projects are established by us once and if there is no structural change afterward, we will go forward with these integrated systems for years and years.</p>
                                    <p>All operations are programmed, from loading data to getting results, from searching algorithms to list limitations.</p>
                                    <p>While our expert support team keeps your data healthy; our expert R&amp;D team will work on adding some innovations to your projects and reports.</p>
                                    <h5>Some of our non-human touch features</h5>
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Loading call lists</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Transferring search results to the desired platform<br/>- List Performance<br/>- Quota Results<br/>- Call Logs<br/>- Survey Results</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project Dialing Settings<br/>- How many times of busy call and missed<br/>- How soon to call<br/>- What time the calls will be start and end during the day?<br/>- What about Display Number information</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Quota Definitions </span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">List restriction information</span></li>
                                    </ul>
                                    <div className="ttm_single_image-wrapper mb-35 mt-35">
                                        <img className="img-fluid" src="../images/features/features-1.png" alt="single-img-twelve" />
                                    </div>
                                    <p>Developing a system with these features may seems the main point, however the important thing is to ensure the continuity of this system and to keep the market-research science mind active at all times.</p>
                                    <p className="mb-35">We are also assertive about delivering the results of the operation to you as well as the operation.</p>
                                    <h3>Why Choose Us?</h3>
                                    <div className="row mt-35">
                                        <div className="col-lg-12">
                                            <div className="row m-0 box-shadow spacing-11">
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                    <div className="ttm_single_image-wrapper">
                                                        <img className="img-fluid" src="../images/features/features-2.jpg" alt="single-img-thirteen" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                    <h6>Dashboard Interface</h6>
                                                    <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Instant tracking of call results in region/province/dealer details</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Following up the last uploaded data</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Due to the instant tracking of the search results, it is possible to reach the desired numbers with an additional list in the remaining regions / provinces / dealers</span></li>
                                                    </ul>
                                                    <h6>Analyze Interface</h6>
                                                    <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Graphical display of answers on the basis of breakdowns</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Table of answers on the basis of breakdowns</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Special analyzes (NPS - Regression)</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Exporting charts and tables to excel file</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default Features;